
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';

import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import { useAddProviderMutation, useDeleteProviderMutation, useEditProviderMutation, useGetAllProvidersQuery } from '../../data/api/provider';
import Loader from '../../components/Common/Loader';
import TableActions from './tableActions';
import { setAddMode, setAdvertiseMode, setDeleteMode, setEditMode, setItemToAdvertise, setItemToDelete, setItemToEdit, setItemToNotify, setItemToView, setNotifyMode, setViewMode, setAddDepartment, setItemToAdd } from '../../data/slices/providerSlice';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import mapToFormData from '../../utils/mapToFormData';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { useGetSectionsSelectionListQuery } from '../../data/api/section';
import { useAdvertisProviderMutation } from '../../data/api/advertising';
import { useCreateNotificationMutation } from '../../data/api/notification';
import AwesomeMapContainer from '../../components/Map/AwesomeMapContainer';
import { useAddProviderDepartmentMutation } from '../../data/api/providerDepartment';
import { useGetCategorysSelectionListQuery } from '../../data/api/category';

function ProvidersPage() {


  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } = useGetAllProvidersQuery();
  const { itemToView, itemToNotify, notifyMode, viewMode, addMode, deleteMode, editMode, itemToEdit, itemToDelete, advertiseMode, itemToAdvertise, itemToAdd , addDepartment } = useSelector(state => state.provider);
  const [createProvider, createProviderResult] = useAddProviderMutation();
  const [editProvider, editProviderResult] = useEditProviderMutation();
  const [deleteProvider, deleteProviderResult] = useDeleteProviderMutation();
  const [advertiseProvider, advertiseProviderResult] = useAdvertisProviderMutation();
  const sections = useGetSectionsSelectionListQuery();
  const categories = useGetCategorysSelectionListQuery();
  const [notifyProvider, notifyProviderResult] = useCreateNotificationMutation();
  const [ addProviderDepartment, addproviderDepartmentResult] = useAddProviderDepartmentMutation();

  const columns = useMemo(
    () => TableHeaders.map((header) => ({
      ...header,
      header: t(header.header),
    })),
    [],
  );



  const setCreateOptions = () => {
    dispatch(setAddMode(true))
  }
  const onSubmit = (body) => {
    body.government = body.government.name;
    body = mapToFormData(body);
    if (addMode) createProvider(body);
    if (editMode && itemToEdit) editProvider({ body, id: itemToEdit.id });

  };
  const onAdvertise = (body) => {
    body = mapToFormData(body);
    advertiseProvider({ body, id: itemToAdvertise.id });

  };

  const onNotify = (body) => {

    body.provider_id = itemToNotify.id;
    body.type = "other";
    body = mapToFormData(body);

    notifyProvider(body);

  };

  const onAddProviderDepartment = (body) => {
    body.provider_id = itemToAdd.id;
    body = mapToFormData(body);
    addProviderDepartment(body);
  }
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  const advertisingTypeList = [
    { 'label': "slider", 'value': "slider" },
    { 'label': "first_banner", 'value': "first_banner" },
    { 'label': "last_banner", 'value': "last_banner" },
    { 'label': "popup", 'value': "popup" },
  ];
  const currencyList = [
    { 'label': "IQD", 'value': "IQD" },
    { 'label': "USD", 'value': "USD" },

  ];
  return (
    <>
      {data && <AwesomeTable
        columns={columns}
        data={data.data}
        showActions
        RowActions={TableActions}
        setCreateOptions={setCreateOptions}
        showTopActions
      />}


      <FullScreenDialog

        data={itemToView}
        columns={[
          { accessorKey: 'name', header: t('Name') },
          { accessorKey: 'email', header: t('Email') },
          { accessorKey: 'phone', header: t('Phone') },
          { accessorKey: 'address', header: t('Address') },
          { accessorKey: 'company_name', header: t('CompanyName') },
          { accessorKey: 'password', header: t('Password') },
          { accessorKey: 'government', header: t('Government') },
          { accessorKey: 'delivery_area', header: t('DeliveryArea') },
          { accessorKey: 'account_limit', header: t('AccountLimit') },
          { accessorKey: 'company_rate', header: t('CompanyRate') },
          { accessorKey: 'work_start_time', header: t('WorkStartTime') },
          { accessorKey: 'work_end_time', header: t('WorkEndTime') },
          { accessorKey: 'currency', header: t('currency') },
          { accessorKey: 'logo', type: 'image', header: t('Logo') },
          { accessorKey: 'bg_image', type: 'image', header: t('BgImage') },
        ]}
        open={viewMode}
        onClose={() => {
          dispatch(setItemToView(null))
          dispatch(setViewMode(false))
        }}
      />

      <AwesomeDialog
        data={itemToEdit}
        columns={[
          { accessorKey: 'name', required: true, header: t('Name') },
          { accessorKey: 'email', required: false, header: t('Email') },
          { accessorKey: 'phone', required: true, header: t('Phone') },
          { accessorKey: 'phone1', required: false, header: t('Phone1') },
          { accessorKey: 'phone2', required: false, header: t('Phone2') },
          { accessorKey: 'address', required: true, header: t('Address') },
          { accessorKey: 'company_name', required: true, header: t('CompanyName') },
          { accessorKey: 'password', required: true, header: t('Password') },
          { accessorKey: 'government', type:"place", required: true, header: t('Government') },
          { accessorKey: 'delivery_area', required: false, header: t('DeliveryArea') },
          { accessorKey: 'account_limit', required: false, header: t('AccountLimit') },
          { accessorKey: 'company_rate', required: false, header: t('CompanyRate') },
          { accessorKey: 'work_start_time', required: true,  type: "time", header: t('WorkStartTime') },
          { accessorKey: 'work_end_time', required: true, type: "time", header: t('WorkEndTime') },
          { accessorKey: 'currency', required: true, type: "select", header: t('currency'), list: currencyList },
          { accessorKey: 'category_ids',required:true,type:'multi-select', header: t('Category'),list: categories.data?.data },
          { accessorKey: 'logo', required: !itemToView, type: 'image', header: t('Logo') },
          { accessorKey: 'bg_image', required: !itemToView, type: 'image', header: t('BgImage') },
        ]}
        open={addMode || editMode}
        onClose={() => {

          dispatch(setEditMode(false))
          dispatch(setAddMode(false))
          dispatch(setItemToEdit(null))

        }}
        onSubmit={onSubmit}
      />
      <AwesomeDialog
        columns={[
          { accessorKey: 'name', required: true, header: t('Name') },
          { accessorKey: 'email', required: false, header: t('Email') },
          { accessorKey: 'phone', required: true, header: t('Phone') },
          { accessorKey: 'address', required: true, header: t('Address') },
          { accessorKey: 'company_name', required: true, header: t('CompanyName') },
          { accessorKey: 'password', required: true, header: t('Password') },
          { accessorKey: 'government', type:"Map", required: true, header: t('Government') },
          { accessorKey: 'delivery_area', required: false, header: t('DeliveryArea') },
          { accessorKey: 'account_limit', required: false, header: t('AccountLimit') },
          { accessorKey: 'company_rate', required: false, header: t('CompanyRate') },
          { accessorKey: 'work_start_time', required: true,  type: "time", header: t('WorkStartTime') },
          { accessorKey: 'work_end_time', required: true, type: "time", header: t('WorkEndTime') },
          { accessorKey: 'currency', required: true, type: "select", header: t('currency'), list: currencyList },
          { accessorKey: 'section_ids', required: true, type: "multi-select", header: t('Sections'), list: sections.data?.data },

          { accessorKey: 'logo', required: !itemToView, type: 'image', header: t('Logo') },
          { accessorKey: 'bg_image', required: !itemToView, type: 'image', header: t('BgImage') },
        ]}
        open={addDepartment}
        onClose={() => {

          dispatch(setAddDepartment(false))
          dispatch(setItemToAdd(null))

        }}
        onSubmit={onAddProviderDepartment}
      />

      <AwesomeDialog
        data={itemToAdvertise}
        columns={[
          { accessorKey: 'lable', required: true, header: t('Label') },
          // { accessorKey: 'description', required: true, header: t('Description') },
          { accessorKey: 'expiry_date', required: true, type: "date", header: t('ExpiryDate') },
          { accessorKey: 'type', type: "select", required: true, header: t('AdvertisingType'), list: advertisingTypeList },
          { accessorKey: 'image_url', type: 'image', required: !itemToAdvertise, header: t('Image') },
        ]}
        open={advertiseMode}
        onClose={() => {
          dispatch(setAdvertiseMode(false))
          dispatch(setItemToAdvertise(null))

        }}
        onSubmit={onAdvertise}
      />
      <AwesomeDialog

        columns={[
          { accessorKey: 'body', required: true, header: t('Body') },
          { accessorKey: 'image', type: 'image', required: false, header: t('Image') },
        ]}
        open={notifyMode}
        onClose={() => {
          dispatch(setNotifyMode(false))
          dispatch(setItemToNotify(null))

        }}
        onSubmit={onNotify}
      />
      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null))
          dispatch(setDeleteMode(false))

        }}
        onConfirm={() => {
          deleteProvider({ id: itemToDelete.id })
        }}
        message={t("DeleteConfirmation")}
      />
   
    </>
  )
}

export default ProvidersPage