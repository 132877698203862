import { Chip } from "@mui/material";

const TableHeaders = [
  // {
  //   accessorKey: "id",
  //   header: "#",
  //   type: "text",
  //   required: false,
  //   size: 140,
  // },
  {
    accessorKey: "order_number",
    header: "OrderNumber",
    type: "text",
    required: false,
    size: 80,
  },
  {
    accessorKey: "total",
    header: "TotalCost",
    type: "text",
    required: false,
    size: 100,
  },
  {
    accessorKey: "company_share",
    header: "CompanyShare",
    type: "text",
    required: false,
    size: 80,
  },
  {
    accessorKey: "status",
    header: "Status",
    type: "text",
    required: false,
    size: 80,
  },
  {
    accessorKey: "by_code",
    header: "ByCode",
    type: "text",
    required: false,
    size: 50,
  },
  {
    accessorKey: "cancelation_note",
    header: "CancelationNote",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "location",
    header: "Location",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "date",
    header: "Date",
    type: "text",
    required: false,
    size: 80,
  },
  {
    accessorKey: "provider_name",
    header: "provider_name",
    type: "text",
    required: false,
    size: 100,
  },
  // {
  //   accessorKey: "invoices_count",
  //   header: "invoices_count",
  //   type: "text",
  //   required: false,
  //   size: 50,
  // },
  {
    accessorKey: "client_name",
    header: "ClientName",
    type: "text",
    required: false,
    size: 100,
  },

  {
    accessorKey: "connection_clicks_phone1",
    header: "ClicksPhone1Conection",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "connection_clicks_phone2",
    header: "ClicksPhone2Conection",
    type: "text",
    required: false,
    size: 140,
  },
];
export default TableHeaders;
