const locale = {
  Offers: "عروض وجوائز",
  Dashboard: "لوحة التحكم",
  DriversRequests: "طلبات السائقين",
  ProvidersRequests: "طلبات التجار",
  ActiveDrivers: "السائقين الفعالين",
  ActiveProviders: "التجار الفعالين",
  Accounts: "الحسابات",
  Orders: "الطلبات",
  TruckTypes: "أنواع الشاحنات",
  SystemParameters: "اعدادات النظام",
  SupportChats: "رسائل الدعم",
  UsersManagement: "ادارة المستخدمين",
  Management: "إدارة",
  Data: "المعطيات",
  System: "النظام",
  Drivers: "السائقين",
  Providers: "التجار",
  Clients: "العملاء",
  Invoices: "الفواتير",
  RecentOrders: "الطلبات الحديثة",
  View: "عرض",
  Accept: "قبول",
  UsageCount: "مرات الاستعمال",

  Name: "الاسم",
  Email: "البريد الإلكتروني",
  Phone: "الهاتف",
  Phone1: "رقم الاتصال الأول",
  Phone2: "رقم الاتصال الثاني",
  ClicksPhone1Conection: " اتصالات الرقم 1",
  ClicksPhone2Conection: "اتصالات الرقم 2",
  Address: "العنوان",
  HasTruck: "لديه شاحنة",
  ProfileImageUrl: "صورة الملف الشخصي",
  IdImage: "صورة الهوية",
  TruckImage: "صورة الشاحنة",
  CompanyImage: "صورة الشركة",
  TruckName: "اسم الشاحنة",
  LoadByTon: "الحمولة بالطن",
  PlateNumber: "رقم اللوحة",
  Symbol: "الرمز",
  Governorate: "المحافظة",
  companyName: "اسم الشركة",
  Stores: "كل المتاجر",
  companyPhone: "رقم هاتف الشركة",
  ConfirmationAcceptRequest: "هل أنت متأكد أنك تريد قبول هذا الطلب؟",
  DeactivateRequest: "هل أنت متأكد أنك تريد إلعاء تفعيل هذا المستخدم",
  NoDataAvailable: "لا يوجد بيانات لعرضها",
  Debt: "دفعة توصيل",
  Payoff: "دفعة للشركة",
  Closed: "مغلقة",
  Confirmation: "تأكيد",
  Cancel: "إلغاء",
  Confirm: "تأكيد",
  Close: "إغلاق",
  Error: "خطأ",
  DELETE: "حذف",
  Deactivate: "الغاء التفعيل",
  Transactions: "عمليات الدفع",
  AddPayoff: "أضف دفعة",
  AcountLocked: "حالة الحساب",
  RequiredAmount: "المبلغ المطلوب",

  OrderNumber: "رقم الطلب",
  ProviderName: "اسم التاجر",
  ClientName: "اسم العميل",
  ClientPhone: "هاتف العميل",
  TotalCost: "التكلفة الإجمالية",
  Date: "التاريخ",
  Coupons: "الكوبونات",
  Code: "الرمز",
  MaxActivation: "الحد الأقصى للتفعيل",
  Amount: "المبلغ",
  Info: "المعلومات",
  currency: "العملة",
  Radius: "مجال التغطية بالمتر",
  Center: "نقطة المنتصف",

  CreatedAt: "تاريخ الإنشاء",
  ProductsPrice: "سعر المنتجات",
  TransportCost: "تكلفة النقل",
  Provider: "التاجر",
  Admins: "المسؤولون",
  OldPassword: "كلمة المرور القديمة",
  NewPassword: "كلمة المرور الجديدة",
  Profile: "الملف الشخصي",

  url: "الرابط",
  NotificationType: "نوع الاشعار",
  other: "عام",
  Body: "الاشعار",
  Connections: "معلومات التواصل",
  Client: "العميل",
  Company: "الشركة",
  Products: "المنتجات",
  Price: "السعر",
  Quantity: "الكمية",
  Trucks: "الشاحنات",
  Image: "الصورة",
  Cost: "التكلفة",
  RequestCountsByStatus: "عدد الطلبات حسب الحالة",
  Count: "العدد",
  DriverNames: "أسماء السائقين",

  DeleteConfirmation: "هل أنت متأكد أنك تريد حذف هذا؟",
  Edit: "تعديل",
  Delete: "حذف",
  Description: "الوصف",
  Value: "القيمة",
  Create: "إنشاء",
  TypeAMessage: "اكتب رسالة",
  Chats: "الدردشات",
  ChatMessages: "رسائل الدردشة",
  RecordingInProgress: "جارٍ التسجيل",
  Continue: "متابعة",
  Login: "تسجيل الدخول",
  Password: "كلمة المرور",
  MustBeAvalidEmail: "يجب أن يكون بريد صالح",
  EmailIsRequired: "عنوان البريد مطلوب",
  PasswordIsRequired: "كلمة المرور مطلوبة",
  CompanyName: "اسم الشركة",
  Government: "المحافظة",
  AccountLimit: "حد قفل الحساب",
  DeliveryArea: "التغطية",
  IsActive: "حالة الحساب",
  Logo: "الشعار",
  BgImage: "صورة الخلفية",
  Vouchers: "العروض",
  Categories: "الفئات",
  Sections: "الفئات الفرعية",
  Section: "الفئة الفرعية",
  New: "جديد",
  Icon: "الأيقونة",
  Label: "العنوان",
  Category: "الفئة",
  Type: "النوع",
  type: "النوع",
  Advertising: "الاعلانات",
  Advertise: "ترويج",
  CompanyRate: "نسبة المنصة",
  popup: "منبثق",
  IsExpired: "انتهت صلاحيته",
  notify: "الاشعارات",
  location: "الموقع",
  branch: "الفرع",
  is_general: "عام",
  store: "في المتجر",
  delivery: "توصيل",
  images: "الصور",
  store_and_delivery: "توصيل وفي المتجر",
  provider_name: "اسم المتجر",
  price_before: "سعر المنتج",
  price_after: "السعر بعد الخصم",
  Pin: "تثبيت",
  PinType: ": تثبيت العرض في  ",
  IsPinned: "مثبت",
  AllVouchers: "كل العروض",
  NewPinnedVoucher: "العروض المثبتة في العروض الجديدة",
  HotPinnedVoucher: "العروض المثبتة في أقوى العروض",
  MostSoldPinnedVoucher:"العروض المثبتة في العروض  الأكثر مبيعا",
  RemovePin: "إلغاء التثبيت",
  RemovePinConfirmation: "هل انت متاكد من الغاء التثبيت لهذا العرض ؟؟",
  ChosseVoucherToPin: "أختر العرض الذي تريد تثبيته",
  VoucherPinndedIn: " : العروض المثبتة في ",
  PinVouchers: "العروض  المثبتة",
  ChooseRemovePinConfirmation: " : أختر العرض الذي تريد إلغاء تثبيته",
  AddDpartment: "إضافة فرع",
  ProviderDepartment: "فروع التجار",
  ProviderEmail: "أيميل التاجر",
  AddVocherToProviderDepartment: "أختر الفروع التي تريد إضافة العرض لها ",
  AddVoucherToProviderDepartment: "أضافة العرض للفروع ",
  AddVoucherToSection: "أضافة العرض الى الفئة:",
  AccountType: "نوع الحساب",
  ProviderType: "حساب تاجر",
  ProviderDepartmentType: "حساب فرع",
  NotificationDetails: "تفاصيل الأشعار",
  YouHaveNewMessageInChat:"لديك رسالة جديدة في الدردشات",
  WorkStartTime:"وقت بداية عمل المتجر",
  WorkEndTime:"وقت نهاية عمل المتجر",
  Notify: "إرسال اشعار",
  first_banner: "البنر الأول",
  last_banner: "البنر الأخير",
  slider: "سلايدر",
  fixed: "مبلغ ثابت",
  percentage: "نسبة",
  ExpiryDate: "تاريخ الانتهاء",
  AdvertisingType: "نوع الاعلان",
  DoneSuccessfully: "تم بنجاح",
  Hot: "أقوى العروض",
  hot: "أقوى العروض",
  new: "العروض الجديدة",
  Promotion: "الترويج",
  NoPromotion : "بلا ترويج",
  IsBlocked: "حظر",
  Birthdate: "تاريخ الميلاد",
  Area: "المنطقة",
  Status: "الحالة",
  pending: "معلق",
  out_for_delivery: "جاري التوصيل",
  delivered : "تم الاستلام",
  not_delivered: "لم يتم الاستلام",
  accepted: "مقبول",
  canceled: "ملغى",
  Clicks: "الرؤية والضغطات",
  Reports: "التقارير",
  ReportsVouchers: "تقارير العروض",
  FromDate: "من تاريخ",
  ToDate: "إلى تاريخ",
  Search: "بحث",
  CancelationNote: "سبب لالغاء",
  Location: "المكان",
  ExportToPDF: "تصدير",
  ByCode: "عبر المسح",
  CompanyShare: "عمولة المنصة",
  most_sold: "الأكثر مبيعاَ",
  CustomSlider: "عروض خاصة",
  ThisFieldIsRequired: "هذا الحقل مطلوب",
  Gifts: "الهدايا",
  RequiredPoints:"النقاط المطلوبة",
  Points: "النقاط",
  voucherSharePoints: "نقاط مشاركة العرض",
  OrderGift: "طلبات الهدايا",
  GiftName : "الهدية",
  AcceptOrderGift: "الموافقة على الطلب",
  CancelOrderGift: "رفض الطلب",
  AcceptOrderGiftMessage: "هل أنت متأكد أنك تربد الموافقة على طلب الهدبة؟؟",
  CancelOrderGiftMessage: "هل أنت متأكد من أنك تريد رفض طلب الهدية؟؟",
  CoverPhoto: "صورة الغلاف",
  open_in_google_maps: "افتح العنوان على الخريطة",
  Total_Connection_Clicks_Phone_2: "مجموع النقرات على الاتصالات (الهاتف 2)",
  Total_Connection_Clicks_Phone_1: "مجموع النقرات على الاتصالات (الهاتف 1)",
  VoucherName: "اسم العرض",
};
export default locale;
