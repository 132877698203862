import { Button, IconButton, Switch, Tooltip } from "@mui/material";
import { useToggleActiveProviderMutation } from "../../data/api/provider";
import { useAdvertisProviderMutation } from "../../data/api/advertising";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import {
  setAdvertiseMode,
  setItemToAdvertise,
  setItemToNotify,
  setNotifyMode,
} from "../../data/slices/providerSlice";
import { blue, pink } from "@mui/material/colors";
import { NotificationAdd } from "@mui/icons-material";

const TableHeaderCell = ({ row }) => {
  const [selectedProvider, selectedProviderResult] =
    useToggleActiveProviderMutation();

  const handleChange = () => {
    selectedProvider({ id: row.original.id });
  };

  return (
    <Switch
      color="secondary"
      checked={row.original.is_active}
      onChange={handleChange}
    />
  );
};
const AdvertiseProvider = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Tooltip arrow placement="left" title={t("Advertise")}>
      <Button
        variant="contained"
        sx={{
          color: pink[800],
          backgroundColor: "#fff700",
        }}
        onClick={() => {
          dispatch(setItemToAdvertise(row.original));
          dispatch(setAdvertiseMode(true));
        }}
      >
        {t("Advertise")}
      </Button>
    </Tooltip>
  );
};

const NotifyProvider = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Tooltip arrow placement="left" title={t("Notify")}>
      <IconButton
        sx={{
          color: blue[800],
        }}
        onClick={() => {
          dispatch(setItemToNotify(row.original));
          dispatch(setNotifyMode(true));
        }}
      >
        <NotificationAdd />
      </IconButton>
    </Tooltip>
  );
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "email",
    header: "Email",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "phone",
    header: "Phone",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "address",
    header: "Address",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "company_name",
    header: "CompanyName",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "connection_clicks_phone1",
    header: "ClicksPhone1Conection",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "connection_clicks_phone2",
    header: "ClicksPhone2Conection",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "status",
    header: "IsActive",
    type: "text",
    required: false,
    size: 140,
    Cell: TableHeaderCell,
  },
  {
    accessorKey: "advertise",
    header: "Advertising",
    type: "text",
    required: false,
    size: 140,
    Cell: AdvertiseProvider,
  },
  {
    accessorKey: "notify",
    header: "notify",
    type: "text",
    required: false,
    size: 140,
    Cell: NotifyProvider,
  },
];
export default TableHeaders;
