import { Check, Close } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";
import { pink } from "@mui/material/colors";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import {
  setAdvertiseMode,
  setCustomSlideMode,
  setItemToAdvertise,
  setItemToCustomSlide,
} from "../../data/slices/voucherSlice";

const TableHeaderCell = ({ row }) => {
  return !row.original.is_expired ? (
    <Check color="success" />
  ) : (
    <Close color="error" />
  );
};

const AdvertiseVoucher = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Tooltip arrow placement="left" title={t("Advertise")}>
        <Button
          variant="contained"
          sx={{
            color: pink[800],
            backgroundColor: "#fff700",
            width: "100%",
            marginBottom: 2,
          }}
          onClick={() => {
            dispatch(setItemToAdvertise(row.original));
            dispatch(setAdvertiseMode(true));
          }}
        >
          {t("Advertise")}
        </Button>
      </Tooltip>
      <Tooltip arrow placement="left" title={t("CustomSlider")}>
        <Button
          variant="contained"
          color="info"
          sx={{
            width: "100%",
          }}
          onClick={() => {
            dispatch(setItemToCustomSlide(row.original));
            dispatch(setCustomSlideMode(true));
          }}
        >
          {t("CustomSlider")}
        </Button>
      </Tooltip>
    </Box>
  );
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "provider_name",
    header: "Provider",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "location",
    header: "Address",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "is_expired",
    header: "IsExpired",
    type: "text",
    required: false,
    size: 140,
    Cell: TableHeaderCell,
  },
  {
    accessorKey: "clicks",
    header: "Clicks",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "connection_clicks_phone1",
    header: "ClicksPhone1Conection",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "connection_clicks_phone2",
    header: "ClicksPhone2Conection",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "advertise",
    header: "Advertising",
    type: "text",
    required: false,
    size: 140,
    Cell: AdvertiseVoucher,
  },
];
export default TableHeaders;
